<template>
  <div class="main-container">
    <div class="section-topic">
      <div class="title-header">
        <h1>Promotion Plan</h1>
      </div>
    </div>
    <div class="section-action">
      <div class="search-action">
        <v-text-field class="btn btn-color btn-border btn-border-color" outlined dense prepend-inner-icon="mdi-magnify"
                      placeholder="Search"
                      v-model="searchItem" @keypress.enter="searchFilterItem"
        >
        </v-text-field>
      </div>
      <div class="btn-new-create-action">
        <v-btn class="btn-create" @click="onCreate()">
          New Promotion
        </v-btn>
      </div>
    </div>
    <div class="section-form-content">
      <div class="block-content">
        <table class="table table-view" v-if="listPromotionPlan.length > 0">
          <thead>
          <tr>
            <th class="text-center">

            </th>

            <th class="text-left">
              Plan Name
            </th>

            <th class="text-left">
              Promotion Name
            </th>


            <th class="text-left">
              Discount
            </th>
            <th class="text-start">
              Type
            </th>


          </tr>
          </thead>
          <tbody>
          <tr v-for="(item,idx) in listPromotionPlan" :key="idx">
            <td>
              {{ idx + 1 }}
            </td>

            <td>
              {{ item.plan_name }}
            </td>
            <td>
              {{ item.name }}
            </td>
            <td>
              {{ item.discount }}
            </td>
            <td>
              <div v-if="item.type === 'percen'" class="percent">
                <span>ຫຼຸດເປັນ %</span>
              </div>
              <div v-else class="price-number">
                <span>ຫຼຸດເປັນຈຳນວນເງິນ</span>
              </div>
            </td>

            <td class="text-end">
              <v-menu transition="slide-y-transition"
                      bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      outlined
                      v-bind="attrs"
                      v-on="on"
                  >
                    <i class="fas fa-ellipsis-v btn-ellipsis"></i>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item>
                    <v-list-item-title class="btn-edit" @click="onEdit(item.id)">Edit</v-list-item-title>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-title class="btn-delete" @click="onDeleted(item.id)">Delete</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>
          </tr>
          </tbody>
        </table>
        <defaultTableNoResult v-else/>
        <Pagination
            v-if="pagination.total_pages > 1"
            :pagination="pagination"
            :offset="offset"
            @paginate="fetchPromotionPlan"
        >
        </Pagination>

        <ModalEdit fullscreen title="Edit Promotion Plan">
          <template v-slot="{ close }">
            <Edit :promotion_plan="promotion_plan" @close="close" @success="fetchPromotionPlan()"/>
          </template>
        </ModalEdit>

        <ModalDelete>
          <template v-slot="{close}">
            <Delete :promotion_plan_id="promotion_plan_id" @close="close" @success="fetchPromotionPlan()"/>
          </template>
        </ModalDelete>

        <Loading v-if="isLoading"/>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "../../../components/Loading";
import Pagination from "../../../components/Paginate/Pagination";
import Edit from "../../../components/promotionPlan/Edit";
import Delete from "../../../components/promotionPlan/Delete";
import defaultTableNoResult from "../../../components/defaultTableNoResult";

export default {
  components: {
    Loading,
    Pagination,
    Edit,
    Delete,
    defaultTableNoResult
  },

  data() {
    return {
      searchItem: "",
      offset: 10,
      pagination: {},
      per_page: 10,
      isLoading: true,
      listPromotionPlan: [],
      promotion_plan: {},
      promotion_plan_id: "",

    }
  },
  methods: {

    filterPromotionPlan(promotion_plan_id) {
      return (
          this.listPromotionPlan.filter((item) => {
            return item.id == promotion_plan_id;
          })[0] || {}
      );
    },

    searchFilterItem() {
      this.fetchPromotionPlan();
    },
    onCreate() {
      this.$router.push({name: 'promotionPlan.create'}).catch(() => {
      });
    },

    onEdit(promotion_plan_id) {
      this.promotion_plan = {
        ...this.filterPromotionPlan(promotion_plan_id)
      };
      this.$store.commit("modalEdit_State", true);
    },

    onDeleted(promotion_plan_id) {
      this.promotion_plan_id = promotion_plan_id;
      this.$store.commit("modalDelete_State", true);
    },

    fetchPromotionPlan() {
      this.isLoading = true;
      const id = this.$route.params.plan_id;
      this.$axios.get(`admin/plan/${id}/plan-promotion`, {
        params: {
          page: this.pagination.current_page,
          per_page: this.per_page,
          filter: this.searchItem,
        }
      }).then((res) => {
        if (res.data.code === 200) {
          this.isLoading = false;
          this.listPromotionPlan = res.data.data.data;
          console.log(this.listPromotionPlan)
          this.pagination = res.data.data.pagination;
          if (!this.listPromotionPlan.length > 0) {
            this.pagination.current_page = this.pagination.current_page - 1;
          }
        }
        this.isLoading = false;

      }).catch((error) => {
        console.log(error)
        this.isLoading = false;
      })
    },
  },
  created() {
    this.fetchPromotionPlan();
  }
}
</script>

<style scoped lang="scss">

.percent {
  span {
    background-color: rgba(249, 202, 36, 0.3);
    padding: 5px 40px;
    border-radius: 30px;
    color: rgba(255, 168, 1, 1.0);
    letter-spacing: 1px;
  }
}

.price-number {
  span {
    background-color: rgba(51, 217, 178, 0.3);
    padding: 5px 15px;
    border-radius: 30px;
    color: rgba(33, 140, 116, 0.8);
    letter-spacing: 1px;
  }
}
</style>
