<template>
  <div class="main-promotion-plan">
    <div class="promotion-plan-content">
      <v-row>
        <v-col cols="6">
          <v-text-field
              outlined
              dense
              label="Promotion Name"
              v-model="promotion_plan.name"
              :hint="`${server_errors.name}`"
              persistent-hint
          >
          </v-text-field>
        </v-col>

        <v-col cols="6">
          <v-select
              outlined
              dense
              label="Discount Type"
              :items="discount_typ"
              item-value="name"
              item-text="value"
              v-model="promotion_plan.type"
              :hint="`${server_errors.type}`"
              persistent-hint
          >
          </v-select>
        </v-col>

        <v-col cols="6" class="mt-4">
          <v-text-field
              class="input-number"
              type="number"
              outlined
              dense
              label="Discount"
              v-model="promotion_plan.discount"
              :hint="`${server_errors.discount}`"
              persistent-hint
          >
          </v-text-field>
        </v-col>


        <v-col cols="6" class="mt-4">
          <v-select
              outlined
              dense
              label="Customer Type"
              :items="customer_type"
              item-text="name"
              item-value="value"
              v-model="promotion_plan.customer_type"
              :hint="`${server_errors.customer_type}`"
          >
          </v-select>
        </v-col>

        <v-col cols="6">
          <label class="text-date-label" for="">Start Date <span
              class="text-show-error">{{ server_errors.start }}</span></label>
          <DatePicker class="date-time"
                      style="width: 100%;"
                      type="date"
                      valueType="format"
                      slot="activator"
                      :append-to-body="true"
                      v-model="promotion_plan.start"

          ></DatePicker>
        </v-col>
        <v-col cols="6">
          <label class="text-date-label" for="">Start Date <span class="text-show-error">{{ server_errors.end }}</span></label>
          <DatePicker class="date-time"
                      style="width: 100%;"
                      type="date"
                      valueType="format"
                      slot="activator"
                      :append-to-body="true"
                      v-model="promotion_plan.end"
          ></DatePicker>
        </v-col>

        <v-col cols="3" class="mt-8">
          <v-btn class="btn-save-change" @click="saveChange" :loading="btnLoading">
            save change
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

export default {
  props: {
    promotion_plan: {},
  },
  components: {
    DatePicker,
  },
  data() {
    return {
      btnLoading: false,
      discount_typ: [
        {
          name: "percen",
          value: "%",
        },
        {
          name: "flat_number",
          value: "Flat Number",
        }
      ],
      customer_type: [
        {
          name: "New",
          value: "new"
        },
        {
          name: "Old",
          value: "old"
        }
      ],


      server_errors: {
        name: "",
        discount: "",
        type: "",
        customer_type: "",
        start: "",
        end: "",
      }

    }
  },
  methods: {
    saveChange() {
      this.btnLoading = true;
      const plan_id = this.$route.params.plan_id;
      this.$axios.post(`admin/plan/${plan_id}/plan-promotion`, this.data).then((res) => {
        if (res.data.code === 200) {
          setTimeout(() => {
            this.$notification.OpenNotification_AddItem_OnSuccess('top-right', 'primary', 3000);
          }, 300);
          this.$router.push({name: "promotionPlan.index"})
        }
        this.btnLoading = false;
      }).catch((error) => {
        if (error.response.status === 422) {
          const obj = error.response.data.errors;
          for (let [key, value] of Object.entries(obj)) {
            this.server_errors[key] = value[0];
          }
        }
        this.btnLoading = false;
      })
    }
  },

  created() {
    console.log(this.promotion_plan)
    const discount = this.promotion_plan.discount.toString();
    console.log(discount)
  }
}
</script>

<style scoped lang="scss">

.main-promotion-plan {
  width: 100%;
  height: auto;
  //  background-color: red;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;

  .promotion-plan-content {
    width: 600px;
    height: 500px;
    //  background-color: #6CB669;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
