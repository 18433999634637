var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-container"},[_vm._m(0),_c('div',{staticClass:"section-action"},[_c('div',{staticClass:"search-action"},[_c('v-text-field',{staticClass:"btn btn-color btn-border btn-border-color",attrs:{"outlined":"","dense":"","prepend-inner-icon":"mdi-magnify","placeholder":"Search"},on:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.searchFilterItem.apply(null, arguments)}},model:{value:(_vm.searchItem),callback:function ($$v) {_vm.searchItem=$$v},expression:"searchItem"}})],1),_c('div',{staticClass:"btn-new-create-action"},[_c('v-btn',{staticClass:"btn-create",on:{"click":function($event){return _vm.onCreate()}}},[_vm._v(" New Promotion ")])],1)]),_c('div',{staticClass:"section-form-content"},[_c('div',{staticClass:"block-content"},[(_vm.listPromotionPlan.length > 0)?_c('table',{staticClass:"table table-view"},[_vm._m(1),_c('tbody',_vm._l((_vm.listPromotionPlan),function(item,idx){return _c('tr',{key:idx},[_c('td',[_vm._v(" "+_vm._s(idx + 1)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.plan_name)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.name)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.discount)+" ")]),_c('td',[(item.type === 'percen')?_c('div',{staticClass:"percent"},[_c('span',[_vm._v("ຫຼຸດເປັນ %")])]):_c('div',{staticClass:"price-number"},[_c('span',[_vm._v("ຫຼຸດເປັນຈຳນວນເງິນ")])])]),_c('td',{staticClass:"text-end"},[_c('v-menu',{attrs:{"transition":"slide-y-transition","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"outlined":""}},'v-btn',attrs,false),on),[_c('i',{staticClass:"fas fa-ellipsis-v btn-ellipsis"})])]}}],null,true)},[_c('v-list',[_c('v-list-item',[_c('v-list-item-title',{staticClass:"btn-edit",on:{"click":function($event){return _vm.onEdit(item.id)}}},[_vm._v("Edit")])],1),_c('v-list-item',[_c('v-list-item-title',{staticClass:"btn-delete",on:{"click":function($event){return _vm.onDeleted(item.id)}}},[_vm._v("Delete")])],1)],1)],1)],1)])}),0)]):_c('defaultTableNoResult'),(_vm.pagination.total_pages > 1)?_c('Pagination',{attrs:{"pagination":_vm.pagination,"offset":_vm.offset},on:{"paginate":_vm.fetchPromotionPlan}}):_vm._e(),_c('ModalEdit',{attrs:{"fullscreen":"","title":"Edit Promotion Plan"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var close = ref.close;
return [_c('Edit',{attrs:{"promotion_plan":_vm.promotion_plan},on:{"close":close,"success":function($event){return _vm.fetchPromotionPlan()}}})]}}])}),_c('ModalDelete',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var close = ref.close;
return [_c('Delete',{attrs:{"promotion_plan_id":_vm.promotion_plan_id},on:{"close":close,"success":function($event){return _vm.fetchPromotionPlan()}}})]}}])}),(_vm.isLoading)?_c('Loading'):_vm._e()],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"section-topic"},[_c('div',{staticClass:"title-header"},[_c('h1',[_vm._v("Promotion Plan")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',{staticClass:"text-center"}),_c('th',{staticClass:"text-left"},[_vm._v(" Plan Name ")]),_c('th',{staticClass:"text-left"},[_vm._v(" Promotion Name ")]),_c('th',{staticClass:"text-left"},[_vm._v(" Discount ")]),_c('th',{staticClass:"text-start"},[_vm._v(" Type ")])])])}]

export { render, staticRenderFns }